import { Spin } from "antd";

export default function UserLoading() {
  return (
    <div
      data-testid="user-loading"
      className="flex justify-center items-center w-full absolute top-0 left-0 h-full z-20 bg-gray-100"
    >
      <Spin size="large" />
    </div>
  );
}
